import * as React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
//@ts-ignore
import ArrowLeft from "../images/arrow_left.svg";
import parse from "html-react-parser";
//@ts-ignore
import Calendar from "../images/calendar_blue.svg";
//@ts-ignore
import Cart from "../images/cart.svg";
import Tabs from "../components/Tabs";
import AnalysisDetailPreparation from "../components/analysisAndPrices/AnalysisDetailPreparation";
import BreadCrumbs from "../BreadCrumbs";
import "./analysis-detail.css";
import { injectIntl } from "gatsby-plugin-intl";
import { useState } from "react";
import Play from "../images/playButton.svg";
import { useQuery } from "@apollo/client";
import { GET_INFOS_BY_LABEL } from "../query/getInfos";
import ReactMarkdown from "react-markdown";

//@ts-ignore
const analysisDetail = ({ intl, pageContext }) => {
  console.log({ intl });

  const { loading, error, data } = useQuery(GET_INFOS_BY_LABEL, {
    variables: {
      label: "how-to-prepare-info",
      locale: intl.locale,
    },
  });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  const [toggleState, setToggleState] = useState(1);

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA]">
        <div className="flex flex-col xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] ">
            <BreadCrumbs
              title={`ServiceMenu.AnalysisAndPrices.AnalysisDetail.HeaderBack`}
              pageContext={pageContext}
            />
          </div>
          <Link to="/analysis-and-prices">
            <div className="flex mb-[2rem] items-center">
              <img src={ArrowLeft} alt="" className="" />
              <p className="font-semibold text-[1.25rem] ml-[10px] text-[#001439] cursor-pointer">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.HeaderBack`,
                  })
                )}
              </p>
            </div>
          </Link>

          <div className="bg-[#EDF2FC] bg-opacity-25 flex sm:flex-row xs:flex-col sm:items-center xs:items-baseline lg:w-auto sm:w-auto sm:h-[11.875rem] xs:h-auto justify-between text-center  items-center mb-2 overflow-hidden rounded-xl sm:p-4 xs:p-[1rem] px-4 border border-[#EDF2FC] border-2  xs:text-[0.7rem] sm:text-sm lg:text-[0.8rem]">
            {/*Description*/}
            <div className="flex flex-col sm:w-[60%]  xs:w-auto ">
              <h4 className="font-semibold justify-around sm:text-[1.75rem] xs:text-[1.25rem] text-left leading-[2rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.name`,
                  })
                )}
              </h4>
              <div className="flex sm:flex-row xs:flex-col sm:mt-[3rem] xs:mt-[1.4rem]">
                <div className="flex sm:items-center justify-left">
                  <img src={Calendar} alt="" className="" />
                  <p className="font-semibold text-[1rem] pl-[8px]">1</p>
                  <p className="font-semibold text-[1rem] pl-[4px]">
                    {parse(
                      intl.formatMessage({
                        id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.term`,
                      })
                    )}
                  </p>
                </div>
                <div className="sm:ml-[2rem] sm:mt-[0rem] xs:mt-[1rem] flex sm:items-center justify-left">
                  <p className="sm:text-[1rem] xs:text-[0.9rem] font-medium">
                    {intl.formatMessage({
                      id: "ServiceMenu.AnalysisAndPrices.AllAnalysisTable.Material",
                    })}
                    :
                  </p>
                  <p className="sm:text-[1rem] xs:text-[0.9rem] font-bold pl-[4px]">
                    {parse(
                      intl.formatMessage({
                        id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.material`,
                      })
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/*Price*/}
            <div className=" mt-[1.4rem] flex justify-end sm:flex-col sm:grid-flow-dense xs:grid xs:grid-flow-col sm:gap-0 xs:gap-[4rem] sm:w-[20%] xs:w-auto text-right">
              <h2 className="font-semibold sm:text-[2rem] xs:text-[1.5rem] text-[#001439] opacity-75">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.price`,
                  })
                )}
                <span className="ml-[4px]">₸</span>
              </h2>

              <button className="lg:mt-[4rem] sm:mt-[5rem]  flex items-center justify-center lg:w-[9rem] xs:w-[8rem] h-[2.8rem] bg-[#618EE4] rounded-[0.6rem]">
                <img src={Cart} alt="" className="cart" />
                <p className="font-semibold text-[1rem] text-white ml-[0.5rem]">
                  {parse(
                    intl.formatMessage({
                      id: `ServiceMenu.AnalysisAndPrices.Button`,
                    })
                  )}
                </p>
              </button>
            </div>
          </div>
          <div className="h-[2.5rem] mt-[2rem] flex border border-b border-[#618EE4] border-opacity-50 border-t-0 border-x-0">
            <div
              className={
                toggleState === 1
                  ? "analysis_detail_active  w-[8rem] flex justify-center items-center cursor-pointer"
                  : " w-[8rem] flex justify-center items-center cursor-pointer"
              }
              onClick={() => setToggleState(1)}
            >
              <p className="sm:text-[1.125rem] text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: "ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Description.Title",
                  })
                )}
              </p>
            </div>
            <div
              className={
                toggleState === 2
                  ? "analysis_detail_active w-[15rem] flex justify-center items-center cursor-pointer"
                  : " w-[15rem] flex justify-center items-center cursor-pointer"
              }
              onClick={() => setToggleState(2)}
            >
              {parse(
                intl.formatMessage({
                  id: "ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.Title",
                })
              )}
            </div>
          </div>
          <div
            className={`flex flex-col lg:items-start justify-center mt-[2.5rem] mb-[10rem] ${
              toggleState === 1 ? "" : "hidden"
            }`}
          >
            <div className="flex flex-col">
              <h4 className="font-semibold text-[1.25rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.ReminderForPatientsTitle`,
                  })
                )}
              </h4>
              <p className="mt-[1rem] font-medium text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.ReminderForPatientsDesc`,
                  })
                )}
              </p>
            </div>
            <div className="mt-[2.5rem] flex flex-col">
              <h4 className="font-semibold text-[1.25rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.GeneralTerms`,
                  })
                )}
              </h4>
              <ul className="list-decimal list-inside mt-[1rem] font-medium text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.GeneralTermsList`,
                  })
                )}
              </ul>
            </div>
            {/*<div className="mt-[2.5rem] lg:flex lg:flex-row xs:flex-col lg:justify-between xs:grid xs:grid-rows-2 xs:justify-center xs:items-center gap-4">*/}
            {/*  <div className="flex flex-col ">*/}
            {/*    <div className="xl:w-[37rem] lg:w-[30rem] sm:w-[37rem] xs:w-[18rem] sm:h-[22rem] xs:h-[13.75rem] rounded-[1rem] analysis_detail_preparation_video1 flex justify-center items-center">*/}
            {/*      <img src={Play} alt="" className="cursor-pointer rounded-[1rem]" />*/}
            {/*    </div>*/}

            {/*    <h4 className="sm:w-[70%] mt-[1rem] font-semibold text-[1.25rem]">*/}
            {/*      {parse(*/}
            {/*          intl.formatMessage({*/}
            {/*            id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.PreparationAnalysisAtHome`,*/}
            {/*          })*/}
            {/*      )}*/}
            {/*    </h4>*/}
            {/*  </div>*/}
            {/*  <div className="flex flex-col">*/}
            {/*    <div className="xl:w-[37rem] lg:w-[30rem] sm:w-[37rem] xs:w-[18rem] sm:h-[22rem] xs:h-[13.75rem] rounded-[1rem] analysis_detail_preparation_video2 flex justify-center items-center">*/}
            {/*      <img src={Play} alt="" className="cursor-pointer rounded-[1rem]" />*/}
            {/*    </div>*/}

            {/*    <h4 className="sm:w-[70%] mt-[1rem] font-semibold text-[1.25rem]">*/}
            {/*      {parse(*/}
            {/*          intl.formatMessage({*/}
            {/*            id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.PreparationAnalysisAtHome`,*/}
            {/*          })*/}
            {/*      )}*/}
            {/*    </h4>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div
            className={`xl:w-full mb-10 mt-5 flex flex-col   px-2  markdown ${
              toggleState === 2 ? "" : "hidden"
            }`}
          >
            <ReactMarkdown>
              {data && data.infos && data.infos.data.length
                ? data.infos.data[0].attributes.info
                : intl.formatMessage({
                    id: "HowToPrepare",
                  })}
            </ReactMarkdown>
          </div>
          {/*<Tabs*/}
          {/*  intl={intl}*/}
          {/*  descriptionTitle={``}*/}
          {/*  preparationTitle={``}*/}
          {/*  description={" "}*/}
          {/*  preparation={" "}*/}
          {/*/>*/}
          {/*<AnalysisDetailDescription />*/}
          {/*<AnalysisDetailPreparation />*/}
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(analysisDetail);
